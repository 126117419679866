import {GoogleMap, useLoadScript, Marker} from "@react-google-maps/api";
import {useCallback, useRef} from "react";

const libraries: ('places')[] = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '80vh',
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};


export default function MapViewer({lat, long}: {lat: number, long: number}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCV7vOmrFFXdo9toq0PbOvuB7_nc8LZhB4',
    libraries,
  });

  const mapRef = useRef<google.maps.Map>();
  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={{lat: lat, lng: long}}
        options={options}
        onClick={()=>{}}
        onLoad={onMapLoad}
      >
        <Marker position={{ lat: lat, lng: long }} />
      </GoogleMap>
    </div>
  );
};