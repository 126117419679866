import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Auth } from "./pages/auth";
import { Home } from "./pages/home";
import { Users } from "./pages/users";
import { Orders } from "./pages/orders";
import Tours from "./pages/tours";
import ToursForm from "./pages/tours/components/ToursForm";
import {Toaster} from "react-hot-toast";
import TourPage from "./pages/tours/components/TourPage";
//import EditTour from "./pages/tours/components/EditTour";


const App = () => {
  return (
    <Router>
      <Toaster
        position="bottom-right"
        toastOptions={{
          className: '',
          duration: 3000,
          style: {
            background: '#2596be',
            color: '#fff',
          },
        }}
      />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tours"
          element={
            <ProtectedRoute>
              <Tours />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tours/:id"
          element={
            <ProtectedRoute>
              <TourPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tours/add"
          element={
            <ProtectedRoute>
              <ToursForm />
            </ProtectedRoute>
          }
        />
        {/*<Route
          path="/tours/edit/:id"
          element={
            <ProtectedRoute>
              <EditTour />
            </ProtectedRoute>
          }
        />*/}
        <Route path="/auth" element={<Auth />} />
      </Routes>
    </Router>
  );
};

export default App;
