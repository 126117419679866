import React, { useState, useRef, useCallback } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const libraries: "places"[] = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "80vh",
};
const center = {
  lat: 43.6532,
  lng: -79.3832,
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

function MapPicker({ formik }: { formik: any }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCV7vOmrFFXdo9toq0PbOvuB7_nc8LZhB4",
    libraries,
  });

  const [selected, setSelected] = useState<google.maps.LatLngLiteral | null>(
    null
  );
  const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      setSelected({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
      formik.setFieldValue("lat", event.latLng.lat());
      formik.setFieldValue("long", event.latLng.lng());
    }
  }, []);

  const mapRef = useRef<google.maps.Map>();
  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <div>
      <Search setSelected={setSelected} formik={formik} />
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {selected && (
          <Marker position={{ lat: selected.lat, lng: selected.lng }} />
        )}
      </GoogleMap>
    </div>
  );
}

interface SearchProps {
  setSelected: React.Dispatch<
    React.SetStateAction<google.maps.LatLngLiteral | null>
  >;
  formik: any;
}

const Search: React.FC<SearchProps> = ({ setSelected, formik }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: new google.maps.LatLng(43.6532, -79.3832),
      radius: 200 * 1000,
    },
  });

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setSelected({ lat, lng });
      formik.setFieldValue("lat", lat);
      formik.setFieldValue("long", lng);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return (
    <div className="search ">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          style={{ marginBlock: "10px" }}
          placeholder="Search for a location"
        />
        <ComboboxPopover>
          {status === "OK" && (
            <ComboboxList>
              {data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
            </ComboboxList>
          )}
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};

export default MapPicker;
