import { Button, InputLabel, TextField, Typography } from "@mui/material";
import  { useEffect, useState } from "react";
import { Box } from "@mui/material";
import toast from "react-hot-toast";
import {Calendar, DateObject} from "react-multi-date-picker";

type Extension = {
  price: number;
  name: string;
};

export type Option = {
  name: string;
  extensions: Extension[];
  languages: string[];
  times: string[];
  dates: string[]
};

export default function AddOption({
  options,
  setOptions,
  index,
}: {
  options: Option[];
  setOptions: any;
  index: number;
}) {
  const [extensions, setExtensions] = useState<Extension[]>([{} as Extension]);
  const [languages, setLanguages] = useState<string[]>([""]);
  const [times, setTimes] = useState<string[]>([""]);
	const [dates, setDates] = useState([[new DateObject()]]);
  const [formattedDates, setFormattedDates] = useState<string[]>([]);

  useEffect(()=>{

    const ranges = []
    for(let i = 0; i < dates.length; i++){
      ranges.push(dates[i].map((d: any)=>d.format()))
    }
    const allDates: any[] = [];

    ranges.forEach((range)=>{
      let startDate = new Date(range[0]);
      let endDate = new Date(range[1]);
      startDate.setDate(startDate.getDate() + 1);
      while(startDate <= endDate){
        allDates.push(startDate.toISOString());
        startDate.setDate(startDate.getDate() + 1);
      }
      allDates.push(startDate.toISOString())
    })

    setFormattedDates(allDates)
    //const dateFields = dates.map((date)=>{
    //  return new Date(date.format());
    //})
    //if(dateFields.length < 2)
    //  return;
    //const arr = []
}, [dates])

  const [option, setOption] = useState<Option>({name: ""} as Option);

  const handleAddOption = ()=>{
    const addedOption: any = {
      name: option.name,
      extensions,
      languages,
      times
    }
    addedOption.extensions = addedOption.extensions.filter((e: any)=>e.price && e.name)
    addedOption.languages = addedOption.languages.filter((l: any)=>l)
    addedOption.times = addedOption.times.filter((t: any)=>t)
    addedOption.dates = formattedDates;
    if(!addedOption.name || !addedOption.extensions.length || !addedOption.languages.length || !addedOption.times.length || !addedOption.dates.length)
    {
      toast.error("All fields are required")
      return;
    }
    setOptions([...options, addedOption])
  }

  return (
    <Box sx={{ gridColumn: "span 2" }}>
      <Box
        sx={{
          background: "#eee",
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Box>
          <InputLabel htmlFor="option">Option</InputLabel>
          <TextField
            sx={{}}
            name="option"
            id="option"
            fullWidth
            value={option.name}
            onChange={(e) => setOption({ ...option, name: e.target.value })}
            inputProps={{
              style: {
                padding: 10,
              },
            }}
          />
        </Box>

        {extensions.map((extension: Extension, index) => (
          <Box key={index} sx={{ display: "flex", gap: 4, alignItems: "end" }}>
            <Box>
              <InputLabel htmlFor="extension">Extension</InputLabel>
              <TextField
                name="extension"
                id="extension"
                sx={{ width: "500px" }}
                value={extension.name}
                onChange={(e) => {
                  extensions[index].name = e.target.value;
                }}
                fullWidth
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
              />
            </Box>
            <Box>
              <InputLabel htmlFor="price">Price</InputLabel>
              <TextField
                name="price"
                id="price"
                type="number"
                value={extension.price}
                onChange={(e) => {
                  extensions[index].price = Number(e.target.value);
                }}
                fullWidth
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
              />
            </Box>
            <Button
              onClick={() => {
                if (extension.name !== "" && extension.price > 0) {
                  setExtensions([...extensions, {} as Extension]);
                }
              }}
              disabled={index !== extensions.length - 1}
              sx={{
                background: "#1E90FF",
                color: "white",
                height: 45,
                "&:hover": { background: "#1E90FF" },
              }}
            >
              Add
            </Button>
          </Box>
        ))}
        <Box sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
          {languages.map((language, index) => (
            <Box key={index}>
              <InputLabel htmlFor="language">Language</InputLabel>
              <TextField
                name="language"
                id="language"
                sx={{ width: "100px" }}
                value={language}
                onChange={(e) => {
                  const newLanguages = [...languages];
                  newLanguages[index] = e.target.value;
                  setLanguages(newLanguages);
                }}
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
              />
              <Button
                onClick={() => {
                  if (language !== "") setLanguages([...languages, ""]);
                }}
                disabled={index !== languages.length - 1}
                sx={{
                  background: "#1E90FF",
                  color: "white",
                  height: 45,
                  marginLeft: 4,
                  "&:hover": { background: "#1E90FF" },
                }}
              >
                Add
              </Button>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
          {times.map((time: string, index: number) => (
            <Box key={index}>
              <InputLabel htmlFor="time">Time</InputLabel>
              <TextField
                name="time"
                id="time"
                sx={{ width: "100px" }}
                value={time}
                onChange={(e) => {
                  const newdates = [...times];
                  newdates[index] = e.target.value;
                  setTimes(newdates);
                }}
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
              />
              <Button
                onClick={() => {
                  if (time !== "") setTimes([...times, ""]);
                }}
                disabled={index !== times.length - 1}
                sx={{
                  background: "#1E90FF",
                  color: "white",
                  height: 45,
                  marginLeft: 4,
                  "&:hover": { background: "#1E90FF" },
                }}
              >
                Add
              </Button>
            </Box>
          ))}
        </Box>
        <Box sx={{display: 'flex',alignItems: 'start', gap: 4}}>
          <Box>
            <InputLabel htmlFor="dates">Dates</InputLabel>
            <Calendar
              format="YYYY-MM-DD"
              value={dates}
              onChange={setDates}
              multiple
              range
              rangeHover
              />
          </Box>
          <Box sx={{display: 'flex',flexDirection: 'column', maxHeight: 300,overflowY: 'scroll',overflowX: 'none', gap: 1, flexWrap: 'wrap', mt: 3}}>
            {formattedDates.map((date: string, index: number) => (
              <Box key={index} sx={{ width: 180,display: 'flex',justifyContent: 'space-between', background: 'white', px: 1, py: '2px'}}>
                <Typography component='p' sx={{fontSize: 12, width: 'fit-content'}}>{new Date(date).toUTCString().slice(0,16)}</Typography>
                <Typography component='p' sx={{cursor: 'pointer',width: 'fit-content', fontSize: 12, color: 'red'}} onClick={() =>setFormattedDates(formattedDates.filter((formattedDate: string) => formattedDate !== formattedDates[index]))}>X</Typography>
              </Box>
            ))}
            </Box>
				</Box>
      </Box>
      {options.length -1 === index &&
      <Button
        sx={{
          background: "#1E90FF",
          color: "white",
          height: 45,
          mt: 2,
          "&:hover": { background: "#1E90FF" },
        }}
        onClick={handleAddOption}
      >
        Save this Option
      </Button>
      }
    </Box>
  );
}
