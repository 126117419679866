import { Layout } from "../../components/layout";
import api from "../../services/api";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  PaginationState,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TableFooter,
  TablePagination,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import { useEffect, useMemo, useState, MouseEvent } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TablePaginationActions from "./components/TablePaginationActions";

const getOrders = async (page: number, limit: number) => {
  const { data } = await api.get(`/admin/orders?page=${page}&limit=${limit}`);
  console.log(data);
  return {
    orders: data.orders,
    totalPages: data.totalPages,
    currentPage: data.currentPage,
  };
};

interface Order {
  _id: string;
  userEmail: string;
  identifier: string;
  createdAt: string;
}

export const Orders = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<Order | null>(null);

  useEffect(() => {
    const fetchOrders = async ({ pageIndex, pageSize }: PaginationState) => {
      const { orders, currentPage, totalPages } = await getOrders(
        pageIndex,
        pageSize
      );
      setOrders(orders);
      setPageCount(currentPage);
    };
    try {
      setLoading(true);
      fetchOrders(pagination);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [pageCount, pagination]);

  const columns = useMemo<ColumnDef<Order>[]>(
    () => [
      {
        accessorKey: "_id",
        header: "ID",
      },
      {
        accessorKey: "identifier",
        header: "Identifier",
      },
      {
        accessorKey: "userEmail",
        header: "User Email",
      },
      {
        accessorKey: "createdAt",
        header: "Created Date",
        cell: ({ row }) => (
          <div>{(new Date(row.original.createdAt)).toUTCString()}</div>
        )
      },
      {
        accessorKey: "action",
        header: "Action",
        cell: ({ row }) => (
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event: MouseEvent<HTMLElement>) =>
                handleClick(event, row.original)
              }
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl) && selectedUser?._id === row.original._id}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleAction("delete")}>Delete</MenuItem>
              <MenuItem onClick={() => handleAction("block")}>Block</MenuItem>
            </Menu>
          </div>
        ),
      },
    ],
    []
  );

  const handleClick = (event: MouseEvent<HTMLElement>, order: Order) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(order);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handleAction = (action: string) => {
    if (selectedUser) {
      if (action === "delete") {
        // Implement delete functionality
        console.log(`Deleting user: ${selectedUser._id}`);
      } else if (action === "block") {
        // Implement block functionality
        console.log(`Blocking user: ${selectedUser._id}`);
      }
    }
    handleClose();
  };

  const table = useReactTable<Order>({
    data: orders,
    columns,
    pageCount,
    state: {
      pagination,
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: newPage,
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: Number(event.target.value),
      pageIndex: 0,
    }));
  };

  return (
    <Layout>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>Loading...</TableCell>
                </TableRow>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  colSpan={3}
                  count={pageCount * pagination.pageSize}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.pageIndex}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <div>
          <span>
            Page{" "}
            <strong>
              {pagination.pageIndex + 1} of {pageCount}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <TextField
              type="number"
              defaultValue={pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: page,
                }));
              }}
              style={{ width: "100px" }}
              variant="outlined"
              size="small"
            />
          </span>
        </div>
      </div>
    </Layout>
  );
};
