import { useFormik } from "formik";
import { Layout } from "../../../components/layout";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import {useEffect, useState} from "react";
import api from "../../../services/api";
import DynamicField from "./DynamicField";
import MapPicker from "./MapPicker";
import toast from "react-hot-toast";
import AddOption, {Option} from "./AddOption";
import {useNavigate} from "react-router-dom";

//const schema = Yup.object().shape({
//  title: Yup.string()
//    .min(3, "Minimum tour name is 3 characters")
//    .max(200, "Maximum tour name is 200 characters")
//    .required("Required"),
//  description: Yup.string()
//    .min(10, "Minimum tour description is 10 characters")
//    .max(800, "Maximum tour description is 800 characters")
//    .required("Required"),
//  country: Yup.string().required("Required"),
//  city: Yup.string(),
//  highlights: Yup.array().required("Required").min(1, "At least one value is required"),
//  included: Yup.array().required("Required").min(1, "At least one value is required"),
//  notIncluded: Yup.array().required("Required").min(1, "At least one value is required"),
//  remember: Yup.array().required("Required").min(1, "At least one value is required"),
//  cancellationPolicy: Yup.array().required("Required").min(1, "At least one value is required"),
//  dates: Yup.array().required("Required").min(1, "At least one Date is required"),
//  meetingPoint: Yup.string().required("Required"),
//	options: Yup.array().required("Required").min(1, "At least one value is required"),
//});

type Location = {
	id: number,
	name: string
}

const initialOption = {
	name: "",
	extensions: [{price: 0, name: 'a'}],
	languages: ['EN'],
	times: ['05:00'],
	dates: []
}

export default function ToursForm() {

	const [countries, setCountries] = useState<Location[]>([]);
	const [cities, setCities] = useState<Location[]>([]);
	const [options, setOptions] = useState<Option[]>([{} as Option]);
	const navigate = useNavigate();

	const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      country: "",
      city: "",
      highlights: [],
      included: [],
      notIncluded: [],
      remember: [],
      cancellationPolicy: [],
      meetingPoint: "",
			options: [initialOption] as Option[],
			lat: 32.5,
			long: 32.5,
    },
    //validationSchema: schema,
    onSubmit: async (values) => {

			values.options = options.slice(1)
			values.options.map((option)=>{
				option.dates = option.dates.map((date)=>{
					return new Date(date).toISOString().slice(0,10)
				})
			})
			const res = await api.post("/admin/tours", values);
			if(res.status===200){
				toast("Tour Added")
				//reset form values
				navigate('/tours/'+res.data.data.tourId)
				formik.resetForm();
			}else{
				toast("Error Adding Tour")
			}
    },
  });


	useEffect(()=>{
		const fillFields = async()=>{
			const countriesRes = await api.get("/admin/countries");
			if(countriesRes.status===200)
				setCountries(countriesRes.data.countries);

		}

		fillFields();
	},[]);

	useEffect(()=>{
		const fillCities = async()=>{
			if(formik.values.country==="")
				return;
			const citiesRes = await api.get("/admin/countries/"+formik.values.country+"/cities");
			if(citiesRes.status===200)
				setCities(citiesRes.data.cities);
		}
		fillCities();
	},[formik.values.country])

	return (
    <Layout>
			<>
			<Typography variant="h5" sx={{ mb: 4, px: {xs: 6, sm: 8, md: 16}, pt: 8, fontWeight: 700, fontSize: 32 }}>
				Add Tour
			</Typography>
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{
          px: {xs: 6, sm: 8, md: 16},
          display: "grid",
          gridTemplateColumns: {xs: '1fr', md:"1fr 1fr"},
					rowGap: 4,
					columnGap: 4,
        }}
      >
        <Box>
          <InputLabel htmlFor="title">Title</InputLabel>
          <TextField
            name="title"
            id="title"
						fullWidth
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
						error={formik.touched.title && Boolean(formik.errors.title)}
						helperText={formik.touched.title && formik.errors.title}
						inputProps={{
							style: {
								padding: 10
							}
						}}
          />
        </Box>
        <Box>
          <InputLabel htmlFor="description">Description</InputLabel>
          <TextField
            name="description"
            id="description"
						multiline
						rows={4}
						fullWidth
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
						error={formik.touched.description && Boolean(formik.errors.description)}
						helperText={formik.touched.description && formik.errors.description}
						inputProps={{
							style: {
								padding: 10
							}
						}}
          />
        </Box>

				<FormControl fullWidth>
					<InputLabel id="country">Country</InputLabel>
					<Select
						labelId="country"
						id="country"
						name="country"
						label="Country"
						value={formik.values.country}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						inputProps={{
							style: {
								padding: 8
							}
						}}
					>
						{countries.map((country: Location) => (
							<MenuItem key={country.id} value={country.id}>
								{country.name}
							</MenuItem>
						))}
					</Select>
					{formik.touched.country && formik.errors.country && <Typography color="error" sx={{fontSize: 12, ml: 2, mt: '4px'}}>{formik.errors.country}</Typography>}
				</FormControl>

				<FormControl fullWidth>
					<InputLabel id="city">City</InputLabel>
					<Select
						labelId="city"
						id="city"
						name="city"
						label="City"
						disabled={cities.length === 0}
						value={formik.values.city}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						inputProps={{
							style: {
								padding: 8
							}
						}}
					>
						{cities.map((city: Location) => (
							<MenuItem key={city.id} value={city.id}>
								{city.name}
							</MenuItem>
						))}
					</Select>
					{formik.touched.city && formik.errors.city && <Typography color="error" sx={{fontSize: 12, ml: 2, mt: '4px'}}>{formik.errors.city}</Typography>}
				</FormControl>

				<Box>
					<DynamicField label='highlights' formik={formik}/>
				</Box>
				<Box>
					<DynamicField label='included' formik={formik}/>
				</Box>
				<Box>
					<DynamicField label='notIncluded' formik={formik}/>
				</Box>
				<Box>
					<DynamicField label='remember' formik={formik}/>
				</Box>
				<Box>
					<DynamicField label='cancellationPolicy' formik={formik}/>
				</Box>

				<Box>
          <InputLabel htmlFor="meetingPoint">Meeting Point</InputLabel>
          <TextField
						sx={{mt: 2}}
            name="meetingPoint"
            id="meetingPoint"
						fullWidth
            value={formik.values.meetingPoint}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
						error={formik.touched.meetingPoint && Boolean(formik.errors.meetingPoint)}
						helperText={formik.touched.meetingPoint && formik.errors.meetingPoint}
						inputProps={{
							style: {
								padding: 10
							}
						}}
          />
        </Box>


				<Typography sx={{ textAlign: "center", gridColumn: 'span 2', fontSize: 24 }}>Options</Typography>

				{
					options.map((_, index) => (
						<AddOption options={options} key={index} index={index} setOptions={setOptions}/>

					))
				}
				<Box sx={{gridColumn: "span 2"}}>
					<InputLabel htmlFor="map">Map</InputLabel>
					<MapPicker formik={formik}/>
				</Box>
				<Button
          variant="contained"
          type="submit"
					//onClick={()=>console.log("A")}
          sx={{ height: 40, width: "100%"}}
        >
          Submit
        </Button>
      </Box>
			</>
    </Layout>
  );
}
