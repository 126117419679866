import React from "react";
import { Box, Button, Modal } from "@mui/material";
import ImageUploader from "./ImageUploader";
import api from "../../../services/api";
import { toast } from 'react-hot-toast';

export default function UploadImages({tourId}: {tourId: string}) {
	const [uploadOpen, setUploadOpen] = React.useState(false);
	const [files, setFiles] = React.useState<File[]>([]);
  const [loading, setLoading] = React.useState(false);
  const handleUploadPhotos = async () => {
    setLoading(true);
    let formData = new FormData();
    let fileSize = 0;

    for (let i = 0; i < files.length; i++) {
      if((fileSize + files[i].size) / (1024 * 1024) > 95){ // exceeded limit, stop to make a request
        //send request
        try {
          const res = await api.post(
            `/admin/${tourId}/gallery`,
            formData,);
          console.log(res)

        } catch (e: any) {
          toast.error("Error uploading Photos")
          setLoading(false)
          return;
        }
        //resetting values
        formData = new FormData();
        i-=1;
        continue;
      }
      formData.append(`images`, files[i]);
      fileSize+=files[i].size;
    }

    //sending last request images
    try {
      const res = await api.post(
        `/admin/${tourId}/gallery`,
        formData,
      );
      console.log(res)

    } catch (e: any) {
        console.log(e)
        toast("Error Uploading Photos");
        setLoading(false)
        return;
    }
    setLoading(false)
    toast("Upload Successful");
    setFiles([]);
  };

  return (
    <>
      {uploadOpen && (
        <Modal open={uploadOpen} onClose={() => setUploadOpen(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <ImageUploader
              files={files}
              setFiles={setFiles}
              handleUploadPhotos={handleUploadPhotos}
              loading={loading}
            />
          </Box>
        </Modal>
      )}
      <Button variant="contained" onClick={() => setUploadOpen(!uploadOpen)}>
        Upload Images
      </Button>
    </>
  );
}
