// ** MUI Imports
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography, { TypographyProps } from "@mui/material/Typography";

// ** Third Party Imports
import { useDropzone } from "react-dropzone";

interface FileProp {
  name: string;
  type: string;
  size: number;
}

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)<TypographyProps>(
  ({ theme }: { theme: any }) => ({
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
  })
);

function ImageUploader({
  files,
  setFiles,
  handleUploadPhotos,
  loading
}: {
  files: File[];
  setFiles: Function;
  handleUploadPhotos: any;
  loading: boolean
}) {
  // ** State
  //   const [files, setFiles] = useState<File[]>([]);
  // ** Hooks
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/webp": [".webp"],
    },
    onDrop: (acceptedFiles: File[]) => {
      setFiles(acceptedFiles.map((file: File) => Object.assign(file)));
    },
  });

  const renderFilePreview = (file: FileProp) => {
    if (file.type.startsWith("image")) {
      return (
        <Box
          component="img"
          width={38}
          height={38}
          alt={file.name}
          src={URL.createObjectURL(file as any)}
        />
      );
    } else {
      return <span> + </span>;
    }
  };

  const handleRemoveFile = (file: FileProp) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter(
      (i: FileProp) => i.name !== file.name
    );
    setFiles([...filtered]);
  };

  const fileList = files.map((file: FileProp, idx: number) => (
    <ListItem key={file.name}>
      <Box
        className="file-details"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box>
          <div className="file-preview">{renderFilePreview(file)}</div>
          <Typography className="file-name" variant="body2">
            {file.name}
          </Typography>
          <Typography className="file-size" variant="body2">
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} MB`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} KB`}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => handleRemoveFile(file)}>X</IconButton>
        </Box>
      </Box>
    </ListItem>
  ));

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <HeadingTypography variant="h5" sx={{ fontSize: 20, mb: 2 }}>
              Click here to upload
            </HeadingTypography>
            <Typography
              color="textSecondary"
              sx={{ cursor: "pointer", fontSize: 14 }}
            >
              click to browse for photos and videos through your machine.
            </Typography>
          </Box>
        </Box>
      </div>
      {files.length ? (
        <>
          <Box
            sx={{
              backgroundColor: "#eee",
              maxHeight: "250px",
              overflowY: "scroll",
            }}
          >
            <List>{fileList}</List>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 2 }}>
            <Button variant="outlined" disabled={loading} onClick={handleUploadPhotos}>
              {loading? "Loading..." : "Upload"}
            </Button>
            {!loading &&
            <Button
              variant="outlined"
              color="error"
              onClick={handleRemoveAllFiles}
            >
              Remove All
            </Button>
            }
          </Box>
        </>
      ) : null}
    </>
  );
}

export default ImageUploader;
