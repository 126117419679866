import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetTour } from "../../../queries/tours";
import { Layout } from "../../../components/layout";
import { Box, Typography } from "@mui/material";
import MapViewer from "./MapViewer";
import UploadImages from "./UploadImages";
import api from "../../../services/api";
import toast from "react-hot-toast";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default function TourPage() {
  const { id } = useParams();
  const [tour, setTour] = useState<any>(null);
  const { data, isLoading } = useGetTour(id as string);
  const [gallery, setGallery] = useState([]);


  const changeCover = async (image: any) => {
    const res = await api.put(`/admin/tours/${id}/cover`, {
      mediaId: image.id,
    });
    if (res.status === 200) {
      toast("Cover Photo Changed");
    } else {
      toast("Error changing Cover Photo");
    }
  };

  useEffect(() => {
    if (!isLoading && data.status === 200) {
      setTour(data?.data?.data.tour);
      setGallery(data?.data?.data.gallery || []);
    }
  }, [data, isLoading, id]);

  if (isLoading || !tour || id === undefined) return <p>Loading...</p>;
  if (!tour) return <p>No tour found</p>;

  const isDateSelected = (date: Date, index: number) => {
    return tour.options[index].dates.some(
      (selectedDate: string) =>
        date.toISOString().toString().slice(0,10) === selectedDate
    )

  };
  const tileClassName = ({ date, view, index}: { date: Date; view: string, index: number }) => {
    if (view === "month") {
      if (isDateSelected(date, index)) {
        return "react-calendar__tile--active"; // Class for highlighted dates
      }
    }
    return "";
  };
  return (
    <Layout>
      <Box
        sx={{
          display: "grid",
          gap: 2,
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          width: "100%",
          px: { xs: 4, md: 8 },
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 700,
            mx: "auto",
            mb: 4,
            gridColumn: "span 2",
          }}
        >
          {tour.title}
        </Typography>
        {tour.thumbnail && (
          <Box
            component="img"
            src={tour.thumbnail}
            alt={tour.title}
            sx={{ width: "80%", mx: "auto", gridColumn: "span 2" }}
          />
        )}
        <Typography sx={{ fontSize: 18, gridColumn: "span 2" }}>
          {tour.description}
        </Typography>
        <Typography sx={{ fontSize: 16, opacity: 0.7 }}>
          Country: {tour.country?.name}
        </Typography>
        <Typography sx={{ fontSize: 16, opacity: 0.7 }}>
          City: {tour.city?.code}
        </Typography>
        <Typography sx={{ fontSize: 16, opacity: 0.7 }}>
          Meeting Point: {tour.location.meetingPoint}
        </Typography>
        <Box />
        <Box>
          <Typography sx={{ fontSize: 20, fontWeight: 700, my: 2 }}>
            Highlights:
          </Typography>
          {tour.highlights.map((highlight: string, index: number) => (
            <li key={index} className="block mb-2">
              {highlight}
            </li>
          ))}
        </Box>


        <Box>
          <Typography sx={{ fontSize: 20, fontWeight: 700, my: 2 }}>
            Included:
          </Typography>
          {tour.included.map((inc: string, index: number) => (
            <li key={index}>{inc}</li>
          ))}
        </Box>

        <Box>
          <Typography sx={{ fontSize: 20, fontWeight: 700, my: 2 }}>
            Not Included:
          </Typography>
          {tour.notIncluded.map((notInc: string, index: number) => (
            <li key={index}>{notInc}</li>
          ))}
        </Box>

        <Box>
          <Typography sx={{ fontSize: 20, fontWeight: 700, my: 2 }}>
            Remember:
          </Typography>
          {tour.remember.map((rem: string, index: number) => (
            <li key={index}>{rem}</li>
          ))}
        </Box>

        <Box>
          <Typography sx={{ fontSize: 20, fontWeight: 700, my: 2 }}>
            Cancellation Policy:
          </Typography>
          {tour.cancellationPolicy.map((policy: string, index: number) => (
            <li key={index}>{policy}</li>
          ))}
        </Box>

        <Box sx={{ gridColumn: "span 2" }}>
          <Typography
            sx={{ fontSize: 18, fontWeight: 700, mb: 2, textAlign: "center" }}
          >
            Options:
          </Typography>
          {tour.options.map((option: any, index: number) => (
            <Box
              key={index}
              sx={{
                background: "#eee",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: 4,
                p: 2,
              }}
            >
              <Typography sx={{ fontSize: 16 }}>Name: {option.name}</Typography>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                Languages:
                {option.languages.map((language: string, index: number) => (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: 14,
                      background: "white",
                      px: 1,
                      py: "4px",
                      borderRadius: "4px",
                    }}
                  >
                    {language}
                  </Typography>
                ))}
              </Box>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                Times:
                {option.times.map((time: string, index: number) => (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: 14,
                      background: "white",
                      px: 1,
                      py: "4px",
                      borderRadius: "4px",
                    }}
                  >
                    {time}
                  </Typography>
                ))}
              </Box>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                Extensions:
                {option.extensions.map((extension: any, index: number) => (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: 14,
                      background: "white",
                      px: 1,
                      py: "4px",
                      borderRadius: "4px",
                    }}
                  >
                    Name: {extension.name}
                    <br /> Price: {extension.price}
                  </Typography>
                ))}
              </Box>
              <Box>
          <Typography sx={{ fontSize: 20, fontWeight: 700, my: 2 }}>
            Dates:
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: 400,
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            <Calendar
              //onClickDay={handleDateClick}  // Handle day clicks
              tileClassName={(e)=>tileClassName({date: e.date, view: e.view,index })} // Apply custom classes to tiles
            />
            <style>
              {`
          .highlight {
            background: var(--green);
            color: white;
            border-radius: 50%;
          }
        `}
            </style>
          </Box>
        </Box>
            </Box>

          ))}
        </Box>

        <Box sx={{ gridColumn: "span 2", mt: 4 }}>
          <Typography
            sx={{ fontSize: 18, fontWeight: 700, mb: 2, textAlign: "center" }}
          >
            Location:
          </Typography>
          <MapViewer lat={tour.location.lat} long={tour.location.long} />
        </Box>
        <Box sx={{ gridColumn: "span 2", mt: 4 }}>
          <Typography
            sx={{ fontSize: 18, fontWeight: 700, mb: 2, textAlign: "center" }}
          >
            Images:
          </Typography>
          <Typography sx={{ fontSize: 14 }}>
            Click on an Image to set it as a cover Photo
          </Typography>
          {gallery.length > 0 ? (
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              {gallery.map((image: any, index: number) => (
                <Box
                  component="img"
                  onClick={() => changeCover(image)}
                  key={index}
                  src={image.fullPath}
                  alt={image.id}
                  style={{
                    width: "auto",
                    height: "150px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
              ))}
            </Box>
          ) : (
            <p className="mx-auto w-full">No Images Uploaded yet</p>
          )}
        </Box>

        <UploadImages tourId={id as string} />
      </Box>
    </Layout>
  );
}
