import axios from 'axios';
import Cookies from 'js-cookie';
// import { refreshToken as getNewTokens } from './authService';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL as string,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const accessToken = Cookies.get('accessToken');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // const newTokens = await getNewTokens();
      // const accessToken = newTokens.accessToken;
      // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      return api(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default api;