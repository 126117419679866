import api from './api';

export const login = async (email: string, password: string) => {
  const response = await api.post('/admin/auth', { email, password }, { withCredentials: true });

  // Cookies.set('accessToken', accessToken, { secure: true, sameSite: 'Lax' });
  // Cookies.set('refreshToken', refreshToken, { secure: true, sameSite: 'Lax' });

  console.log(response);
  return {data: response.data, status: response.status};
};

export const register = async (email: string, password: string) => {
  const response = await api.post('/admin/register', { email, password });
  return response.data;
};

export const refreshToken = async () => {
  const response = await api.post('/admin/refresh-token', {}, { withCredentials: true });
  // const { accessToken, refreshToken: newRefreshToken } = response.data.tokens;

  // Cookies.set('accessToken', accessToken, { secure: true, sameSite: 'Strict' });
  // Cookies.set('refreshToken', newRefreshToken, { secure: true, sameSite: 'Strict' });

  return response.data;
};

export const logout = async () => {
  const response = await api.post('/admin/logout', {}, { withCredentials: true });
  // Cookies.remove('accessToken');
  // Cookies.remove('refreshToken');
  return response.data;
};