import { Box, Typography } from "@mui/material"
import { Layout } from "../../components/layout"
import axios from "axios";

export const refreshToken = async () => {
  try{

    const response = await axios.post('https://server.baitportal.com/admin/refresh-token', {}, { withCredentials: true });
    // const { accessToken, refreshToken: newRefreshToken } = response.data.tokens;
    // Cookies.set('accessToken', accessToken, { secure: true, sameSite: 'Strict' });
    // Cookies.set('refreshToken', newRefreshToken, { secure: true, sameSite: 'Strict' });
    return response;
  }catch(e: any){
    console.log(e)
    if(window.location.href ! === '/auth')
      window.location.href='/auth'
  }
};

export const Home = () => {

  setInterval(async() => {
      await refreshToken()

  }, 1000*60*5)

  return (
    <Layout>
      <Box sx={{
        width: '100%',
        height: '80vh',
        display: 'flex',
        justifyContent: 'center' ,
        alignItems: 'center',
      }}>
        <Box>
          <img src="./logo.svg" alt="baitportal.com" />
          <Typography paragraph>
            Home page is under construction.
          </Typography>
        </Box>
      </Box>
    </Layout>
  )
}