import {Box, Button} from '@mui/material'
import {Layout} from '../../components/layout'
import {useNavigate} from 'react-router-dom'
import ToursTable from './components/ToursTable';

export default function Tours() {
	const navigate = useNavigate();
	return (
		<Layout>
			<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%"}}>
				<Button variant="contained" onClick={() => navigate('/tours/add')}>Add Tour</Button>
				<ToursTable />
			</Box>
		</Layout>
	)
}
